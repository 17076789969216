/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import React from "react";
// import React, { useState, useEffect } from "react";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import AssetInfoTable from "layouts/search/components/AssetInfoTable";
import DataTable from "examples/Tables/DataTable";
import PropTypes from "prop-types";

// import $ from "jquery";

function AssetTable({ rows }) {
  // const count = 0;
  const columns = [
    { Header: "IP", accessor: "ip", align: "left" },
    { Header: "资产类型", accessor: "asset_type", align: "center" },
    { Header: "应用信息", accessor: "app_info", align: "left" },
    { Header: "协议", accessor: "protocol", align: "center" },
    { Header: "端口", accessor: "port", align: "center" },
    { Header: "发现时间", accessor: "find_time", align: "center" },
    { Header: "指纹信息", accessor: "more_info", align: "center" },
  ];

  // getRows();

  console.log(rows);
  // getRows();

  // useEffect(() => {
  //   getRows();
  // }, [count]);

  return (
    <Card id="delete-account">
      <MDBox pt={3} px={2}>
        <MDTypography variant="h6" fontWeight="medium">
          检索结果
        </MDTypography>
      </MDBox>
      <MDBox pt={1} pb={2} px={2}>
        <MDBox
          component="ul"
          display="flex"
          flexDirection="column"
          p={0}
          m={0}
          height="500px"
          style={{
            overflow: "hidden",
            overflowY: "scroll",
          }}
        >
          <DataTable table={{ columns, rows }} noEndBorder />
        </MDBox>
      </MDBox>
    </Card>
  );
}

AssetTable.propTypes = {
  rows: PropTypes.string.isRequired,
};

export default AssetTable;
