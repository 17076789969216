/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import GradientLineChart from "examples/Charts/LineCharts/GradientLineChart";
import PieChart from "examples/Charts/PieChart";
import DataTable from "examples/Tables/DataTable";

import InfoCard from "layouts/attack/components/InfoCard";
import Details from "layouts/attack/components/Details";
// import tableData from "layouts/attack/data/tableData";

import $ from "jquery";

function Attack() {
  const count = 0;
  const [malicious, setMalicious] = useState(3);
  const [hostNum, setHostNum] = useState(4);
  const [warningNum, setWarningNum] = useState(5);
  const [warningType, setWarningType] = useState({
    labels: ["Windows", "A", "B", "C"],
    datasets: {
      label: "Protocol",
      data: [50, 10, 20, 30],
      backgroundColors: ["secondary", "light", "info", "success"],
    },
  });
  const [warningLine, setWarningLine] = useState({
    labels: ["11.1", "11.2", "11.3", "11.4", "11.5", "11.6", "11.7", "11.8", "11.9"],
    datasets: [
      {
        label: "Type 1",
        color: "info",
        data: [360, 200, 283, 160, 45, 84, 134, 280, 340],
      },
      {
        label: "Type 2",
        data: [500, 100, 300, 220, 500, 250, 400, 230, 500],
      },
    ],
  });
  const columns = [
    { Header: "攻击类型", accessor: "attack_type", align: "left" },
    { Header: "协议类型", accessor: "protocol", align: "center" },
    { Header: "时间", accessor: "attack_time", align: "center" },
    { Header: "源地址", accessor: "src_address", align: "center" },
    { Header: "目的地址", accessor: "dst_address", align: "center" },
    { Header: "详细信息", accessor: "more_info", align: "center" },
  ];
  const [rows, setRows] = useState([]);

  const getRows = () => {
    $.get("/attack_info/details", (res) => {
      const tempRows = [];
      console.log(res.details);
      res.details.forEach((item) => {
        const it = {
          attack_type: item.attack_type,
          protocol: item.proto,
          attack_time: item.attack_time,
          src_address: item.src_address,
          dst_address: item.dst_address,
          more_info: <Details detail={item.more_info} />,
        };
        tempRows.push(it);
      });
      setRows(tempRows);
    });
  };

  const getAttackInfo = () => {
    $.get("/attack_info/overview", (res) => {
      setMalicious(res.warning_num);
      setHostNum(res.warning_ip_num);
      setWarningNum(res.warning_type_num);
      setWarningType(res.warning_type);
      setWarningLine(res.warning_total);
    });
  };

  useEffect(() => {
    getAttackInfo();
    getRows();
  }, [count]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} xl={2}>
            <MDBox mb={1.5}>
              <InfoCard icon="pest_control" color="warning" title="恶意行为" value={malicious} />
            </MDBox>
            <MDBox mb={1.5}>
              <InfoCard icon="build" color="dark" title="相关主机" value={hostNum} />
            </MDBox>
            <MDBox mb={1.5}>
              <InfoCard icon="account_balance" title="告警事件种类" value={warningNum} />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={3}>
              <PieChart title="告警类型" description="" chart={warningType} height="250px" />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <MDBox mb={3}>
              <GradientLineChart
                title="告警数量趋势"
                description=""
                chart={warningLine}
                height="250px"
              />
            </MDBox>
          </Grid>
        </Grid>
        <MDBox mt={0}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                pt={1}
                style={{
                  overflow: "hidden",
                  overflowY: "scroll",
                }}
              >
                <DataTable table={{ columns, rows }} canSearch="true" noEndBorder />
              </MDBox>
            </Card>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Attack;
