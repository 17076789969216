/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import HorizontalBarChart from "examples/Charts/BarCharts/HorizontalBarChart";
import PieChart from "examples/Charts/PieChart";

import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
// import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
// import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

import $ from "jquery";

function Dashboard() {
  const [assetNum, setAssetNum] = useState(1);
  const [portNum, setPortNum] = useState(2);
  const [moduleNum, setModuleNum] = useState(2);
  const [warningNum, setWarningNum] = useState(2);
  const [protocol, setProtocal] = useState({
    labels: ["HTTP"],
    datasets: {
      label: "Protocol",
      data: [12],
      backgroundColors: ["secondary"],
    },
  });
  const [element, setElement] = useState({
    labels: ["A", "B", "C", "D", "E"],
    datasets: [
      {
        label: "Protocol",
        data: [1, 2, 3, 4, 5],
      },
    ],
  });
  const [assets, setAssets] = useState({
    labels: [
      "Windows10",
      "Android 8.1.0",
      "Windows XP",
      "Android 5.1.1",
      "Windows7",
      "Mac OS X 10.4",
      "Other",
      "Ubuntu 10.4",
      "Windows 7",
    ],
    datasets: [
      {
        label: "Dataset 1",
        data: [3, 2, 2, 1, 1, 1, 1, 1, 1],
      },
    ],
  });
  const [ports, setPorts] = useState({
    labels: ["80", "443", "22", "8080", "3000", "8000", "3306"],
    datasets: { label: "Sales", data: [80, 50, 40, 22, 10, 8, 5] },
  });
  const [change, setChange] = useState({
    labels: ["11.1", "11.2", "11.3", "11.4", "11.5", "11.6", "11.7", "11.8", "11.9"],
    datasets: { label: "Total", data: [50, 40, 300, 220, 500, 250, 400, 230, 500] },
  });
  const count = 0;

  const getOverviewInfo = () => {
    // {'asset_num': 5, 'port_num': 9, 'module_num': 123, 'warning_num': 2}
    $.get("/overview/num_info", (res) => {
      setAssetNum(res.asset_num);
      setPortNum(res.port_num);
      setModuleNum(res.module_num);
      setWarningNum(res.warning_num);
      console.log(warningNum);
    });
  };

  const getDetailsInfo = () => {
    $.get("/overview/details_info", (res) => {
      setProtocal(res.protocol);
      setElement(res.element);
      setAssets(res.assets);
      setPorts(res.ports);
      setChange(res.change);
      console.log(element);
    });
  };

  useEffect(() => {
    // Update the document title using the browser API
    getOverviewInfo();
    getDetailsInfo();
  }, [count]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="computer"
                title="资产数量"
                count={assetNum}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard icon="airlinestops" title="资产种类" count={portNum} />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="article"
                title="应用种类"
                count={moduleNum}
              />
            </MDBox>
          </Grid>
          {/* <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="notification_important"
                title="存在漏洞组件数量"
                count={warningNum}
              />
            </MDBox>
          </Grid> */}
        </Grid>
        <MDBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <PieChart title="协议类型" description="" chart={protocol} />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <HorizontalBarChart title="资产产品排行" description="" chart={assets} />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <HorizontalBarChart title="应用类型" description="" chart={element} />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mt={2}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsBarChart
                  color="info"
                  title="端口数量"
                  description="常见端口"
                  date=""
                  chart={ports}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={8}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="dark"
                  title="资产变化趋势"
                  description="近十天统计数据"
                  date=""
                  chart={change}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
