// @mui material components
import React from "react";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

function Details({ detail }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button onClick={handleOpen}>详情</Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title"> 详细信息 </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description"> {detail} </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>关闭</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

Details.defaultProps = {
  detail: "",
};

Details.propTypes = {
  detail: PropTypes.arrayOf(PropTypes.object),
};

export default Details;
