/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Checkbox from "@mui/material/Checkbox";
import DataTable from "examples/Tables/DataTable";
import { useState, useLayoutEffect } from "react";
import $ from "jquery";

function Configs() {
  const count = 1;
  const columns = [
    { Header: "是否采用", accessor: "is_used", width: "10%", align: "left" },
    { Header: "脚本名称", accessor: "script_type", align: "left" },
    { Header: "脚本介绍", accessor: "intro", align: "left" },
  ];
  const [rows, setRows] = useState([
    {
      is_used: <Checkbox defaultChecked />,
      script_type: "test",
      intro: "test",
    },
  ]);

  const getScripts = () => {
    $.get("/configs_info/scripts", (res) => {
      const tempRows = [];
      res.scripts.forEach((item) => {
        const it = {
          is_used: <Checkbox defaultChecked />,
          script_type: item.script,
          intro: item.intro,
        };
        tempRows.push(it);
      });
      setRows(tempRows);
    });
  };

  useLayoutEffect(() => {
    getScripts();
  }, [count]);

  // console.log(rows);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="secondary"
                borderRadius="lg"
                coloredShadow="secondary"
              >
                <MDBox
                  pt={0}
                  px={2}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <MDTypography variant="h6" color="white">
                    插件
                  </MDTypography>
                  <MDButton variant="gradient" color="light">
                    确定
                  </MDButton>
                </MDBox>
              </MDBox>
              <MDBox pt={3}>
                <DataTable table={{ columns, rows }} canSearch noEndBorder />
              </MDBox>
            </Card>
          </Grid>
          {/* <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="secondary"
                borderRadius="lg"
                coloredShadow="secondary"
              >
                <MDBox
                  pt={0}
                  px={2}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <MDTypography variant="h6" color="white">
                    检测脚本
                  </MDTypography>
                  <MDButton variant="gradient" color="light">
                    确定
                  </MDButton>
                </MDBox>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns: pColumns, rows: pRows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid> */}
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Configs;
