/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
// import Divider from "@mui/material/Divider";
// import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDButton from "components/MDButton";

// Billing page components
// import ListInfo from "layouts/search/components/ListInfo";
import PropTypes from "prop-types";

function Statistical({ times, ports, types }) {
  return (
    <Card sx={{ height: "100%" }}>
      <MDBox pt={2} pb={2} px={2}>
        <MDBox mb={0}>
          <MDTypography variant="caption" color="text" fontWeight="bold" textTransform="uppercase">
            时间
          </MDTypography>
        </MDBox>
        <MDBox component="ul" flexDirection="column" p={1} m={0} sx={{ listStyle: "none" }}>
          {/* <ListInfo name="2020" value="2390" /> */}
          {times}
        </MDBox>
        <MDBox mt={1} mb={0}>
          <MDTypography variant="caption" color="text" fontWeight="bold" textTransform="uppercase">
            端口
          </MDTypography>
        </MDBox>
        <MDBox component="ul" flexDirection="column" p={1} m={0} sx={{ listStyle: "none" }}>
          {/* <ListInfo name="443" value="111" /> */}
          {/* <ListInfo name="80" value="1275" />
          <ListInfo name="443" value="1101" />
          <ListInfo name="8080" value="7" />
          <ListInfo name="81" value="5" />
          <ListInfo name="5223" value="2" /> */}
          {ports}
        </MDBox>
        <MDBox mt={1} mb={0}>
          <MDTypography variant="caption" color="text" fontWeight="bold" textTransform="uppercase">
            类型(Top 10)
          </MDTypography>
        </MDBox>
        <MDBox component="ul" flexDirection="column" p={1} m={0} sx={{ listStyle: "none" }}>
          {/* <ListInfo name="Other" value="1928" />
          <ListInfo name="Windows 8" value="309" />
          <ListInfo name="Windows 10" value="73" />
          <ListInfo name="Windows XP" value="19" />
          <ListInfo name="Windows 7" value="17" />
          <ListInfo name="Android 11" value="8" />
          <ListInfo name="Android 10" value="7" />
          <ListInfo name="Android 12" value="4" />
          <ListInfo name="iOS 15.6" value="3" />
          <ListInfo name="Debian" value="3" /> */}
          {types}
        </MDBox>
      </MDBox>
    </Card>
  );
}

Statistical.propTypes = {
  times: PropTypes.string.isRequired,
  ports: PropTypes.string.isRequired,
  types: PropTypes.string.isRequired,
};

export default Statistical;
