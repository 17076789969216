// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { useState } from "react";
// import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import DateFnsUtils from "@date-io/date-fns";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
// import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
// import DataTable from "examples/Tables/DataTable";

// import SearchBar from "layouts/search/components/SearchBar";
import AssetTable from "layouts/search/components/AssetTable";
import Statistical from "layouts/search/components/Statistical";
import AssetInfoTable from "layouts/search/components/AssetInfoTable";
import ListInfo from "layouts/search/components/ListInfo";
import $ from "jquery";

// Data
// import authorsTableData from "layouts/tables/data/authorsTableData";
// import projectsTableData from "layouts/tables/data/projectsTableData";

function Search() {
  // const { columns, rows } = authorsTableData();
  // const { columns: pColumns, rows: pRows } = projectsTableData();
  const [assettype, setAssetType] = useState("");
  const [prototype, setProtoType] = useState("");
  const [ipaddress, setIpAddress] = useState("");
  const [port, setPort] = useState("");
  const [selectedDate1, handleDateChange1] = useState(new Date());
  const [selectedDate2, handleDateChange2] = useState(new Date());
  const [rows, setRows] = useState([]);
  const [times, setTimes] = useState([]);
  const [ports, setPorts] = useState([]);
  const [types, setTypes] = useState([]);
  const handleAssetTypeChange = (e) => {
    setAssetType(e.target.value);
  };

  const handleProtoTypeChange = (e) => {
    setProtoType(e.target.value);
  };

  const handleIpAddressChange = (e) => {
    setIpAddress(e.target.value);
  };

  const handlePortChange = (e) => {
    setPort(e.target.value);
  };

  const submitSearchInfo = () => {
    $.get(
      "/search_info/get_items",
      {
        // assettype,
        ipaddress,
        port,
        selectedDate1: selectedDate1.getTime(),
        selectedDate2: selectedDate2.getTime(),
      },
      (res) => {
        const tempRows = [];
        // console.log(res.details);
        res.details.forEach((item) => {
          const it = {
            ip: item.ip,
            asset_type: item.asset_type,
            app_info: item.app_info,
            protocol: item.protocol,
            port: item.port,
            find_time: item.find_time,
            more_info: <AssetInfoTable detail={item.more_info} />,
          };
          tempRows.push(it);
        });
        setRows(tempRows);
        //
        const tempTimes = [];
        res.times.forEach((item) => {
          const it = <ListInfo name={item[0]} value={item[1]} />;
          tempTimes.push(it);
        });
        setTimes(tempTimes);
        //
        const tempPorts = [];
        res.ports.forEach((item) => {
          const it = <ListInfo name={item[0]} value={item[1]} />;
          tempPorts.push(it);
        });
        setPorts(tempPorts);
        //
        const tempTypes = [];
        res.types.forEach((item) => {
          const it = <ListInfo name={item[0]} value={item[1]} />;
          tempTypes.push(it);
        });
        setTypes(tempTypes);
      }
    );
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={0} pb={3}>
        <Grid item xs={12} md={12}>
          <Card id="delete-account">
            <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
              <MDTypography variant="h5" fontWeight="medium">
                数据检索
              </MDTypography>
              <MDButton variant="gradient" color="dark" onClick={submitSearchInfo}>
                搜索
              </MDButton>
            </MDBox>
            <MDBox p={2}>
              <Grid container spacing={3}>
                <Grid item xs={3} md={2}>
                  <MDInput
                    type="asset_type"
                    label="资产类型"
                    value={assettype}
                    onChange={handleAssetTypeChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={3} md={2}>
                  <MDInput
                    type="protocol_type"
                    label="协议类型"
                    value={prototype}
                    onChange={handleProtoTypeChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6} md={2}>
                  <MDInput
                    type="ip_address"
                    label="IP地址"
                    value={ipaddress}
                    onChange={handleIpAddressChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6} md={1}>
                  <MDInput
                    type="port_address"
                    label="端口"
                    value={port}
                    onChange={handlePortChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6} md={5}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DateTimePicker
                      label="开始时间"
                      inputVariant="outlined"
                      value={selectedDate1}
                      onChange={handleDateChange1}
                    />
                    &nbsp;
                    <DateTimePicker
                      label="结束时间"
                      inputVariant="outlined"
                      value={selectedDate2}
                      onChange={handleDateChange2}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              </Grid>
            </MDBox>
          </Card>
        </Grid>
      </MDBox>
      <MDBox pt={0} pb={3}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={2}>
            <Statistical times={times} ports={ports} types={types} />
          </Grid>
          <Grid item xs={12} md={10}>
            <AssetTable rows={rows} />
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Search;
